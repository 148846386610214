import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Masks from "./masks.svg";
import MasksInverted from "./masks_inverted.svg";
import formatTitle from "../../components/common/formatTitle";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  withBg: {
    padding: theme.layout.paddingRegular,
    backgroundColor: theme.palette.background.default,
  },
  morePadding: {
    paddingTop: "8%",
    paddingBottom: "8%",
  },
  bgMasks: {
    backgroundImage: `url(${MasksInverted})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 50%",
    backgroundSize: "auto 100%",
  },
  bgMasksInverted: {
    backgroundImage: `url(${Masks})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 50%",
    backgroundSize: "auto 100%",
  },
  illustrationRight: {
    backgroundPosition: "100% 0%",
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  titleContent: {
    fontWeight: 600,
  },
  paddedLg: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  paddedMob: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rnd: {
    borderRadius: 5,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804);",
  },
  noMargin: {
    margin: 0,
  },
  tagWrapper: {
    marginBottom: theme.spacing(1),
  },
  tag: {
    display: "inline-box",
    padding: "2px 4px",
    borderRadius: 3,
    backgroundColor: "rgba(77, 161, 255, 0.14)",
    color: theme.palette.primary.dark,
  },
}));

const SkillsHeading = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const {
    mainTitle,
    title1,
    content1,
    title2,
    content2,
    imageSizeOnMobile,
    reverse,
    illustrationInverted,
    illustrationPosition,
    lg,
  } = props;

  return (
    <Grid
      item
      className={clsx({
        [classes.paddingRegular]: matches,
        [classes.paddingRegularMobile]: !matches,
        [classes.withBg]: true,
        [classes.bgMasks]: !illustrationInverted,
        [classes.bgMasksInverted]: illustrationInverted,
        [classes.morePadding]: matches,
        [classes.illustrationRight]: illustrationPosition === "R",
      })}
    >
      <Grid
        container
        direction={
          matches ? (reverse ? "row-reverse" : "row") : "column-reverse"
        }
        justify="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item container direction="column" xs={11} sm={6}>
          <Grid item>
            <Typography
              variant="h1"
              color="textSecondary"
              className={classes.title}
            >
              {mainTitle ? formatTitle(mainTitle) : ""}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.titleContent}
            >
              {title1}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {content1}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.titleContent}
            >
              {title2}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {content2}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={imageSizeOnMobile} xs={11} md={5}>
          <img
            src={props.src}
            alt="roadshare"
            width="100%"
            height="auto"
            className={classes.rnd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

SkillsHeading.defaultProps = {
  imageSizeOnMobile: 4,
  reverse: false,
  illustrationInverted: false,
  illustrationPosition: "L",
  lg: 5,
};

SkillsHeading.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  content1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  content2: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  reverse: PropTypes.bool.isRequired,
  illustrationInverted: PropTypes.bool.isRequired,
  illustrationPosition: PropTypes.string.isRequired, // L || R
  imageSizeOnMobile: PropTypes.number.isRequired,
  lg: PropTypes.number.isRequired,
};

export default SkillsHeading;
